import { setting } from "@/api/setting";

const state = {};
const mutations = {};
const actions = {
  // 角色列表
  setting({ commit }) {
    return new Promise((resolve, reject) => {
      setting()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
