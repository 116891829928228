import router from "./router";
import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import {
  getToken,
  getMacineState

} from "@/utils/auth"; // get token from cookie
// import { EPSILON } from "core-js/core/number";

NProgress.configure({
  showSpinner: false,
}); // NProgress Configuration

const whiteList = ["/oauth_wechat"]; // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  // document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken();
  const exists = parseInt(getMacineState())
  if (hasToken) {
    if (to.path === "/oauth_wechat") {
      // if is logged in, redirect to the home page
      next({
        path: "/",
      });
      NProgress.done();
    } else {
      if (to.path === "/equity_bind") {
        if (exists === 1) {
          next({
            path: "/",
          });
        } else {
          next();
          NProgress.done();
        }

      } else {
        if (exists === 1) {
          next();
        } else {
          next("/equity_bind");
          NProgress.done();
        }
        //   // let res = await store.dispatch("machine/check_machine_exists");
        //   // if (res.code === "1" && res.data.bind_machine_state === "1") {
        //   //   next();
        //   // } else {
        //   //   next("/equity_bind");
        //   // }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/oauth_wechat?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});