import request from "@/utils/request";
export function setting() {
  let url = "/api/setting";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}
