import {
  default_lottery,
  default_lottery_count,
  do_default_lottery,
  new_lottery,
  new_lottery_count,
  do_new_lottery,
  user_lottery_log,
  gundong_lottery_log,
  new_lottery_log,
} from "@/api/lottery";

const state = {};
const mutations = {};
const actions = {
  default_lottery({ commit }, param) {
    return new Promise((resolve, reject) => {
      default_lottery()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  default_lottery_count({ commit }, param) {
    return new Promise((resolve, reject) => {
      default_lottery_count()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  do_default_lottery({ commit }, param) {
    return new Promise((resolve, reject) => {
      do_default_lottery()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  new_lottery({ commit }, param) {
    return new Promise((resolve, reject) => {
      new_lottery()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  new_lottery_count({ commit }, param) {
    return new Promise((resolve, reject) => {
      new_lottery_count()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  do_new_lottery({ commit }, param) {
    return new Promise((resolve, reject) => {
      do_new_lottery()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  user_lottery_log({ commit }, param) {
    return new Promise((resolve, reject) => {
      user_lottery_log()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  gundong_lottery_log({ commit }, param) {
    return new Promise((resolve, reject) => {
      gundong_lottery_log()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  new_lottery_log({ commit }, param) {
    return new Promise((resolve, reject) => {
      new_lottery_log()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
