import request from '@/utils/request'

export function import_merchant_add(data) {
    let url = "/api/import_merchant";
    return request({
      url: url,
      method: 'post',
      data
    });
  }
  
export function sms_add(data) {
    let url = "/api/sms";
    return request({
      url: url,
      method: 'post',
      data
    });
  }
  