import {
  address_list,
  add_address,
  address_info,
  edit_address,
  delete_address,
  get_default,
  set_default,
} from "@/api/address";

const state = {};
const mutations = {};
const actions = {
  // 地址列表
  address_list({ commit }, param) {
    return new Promise((resolve, reject) => {
      address_list()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 添加地址
  add_address({ commit }, param) {
    return new Promise((resolve, reject) => {
      add_address(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取地址详情
  address_info({ commit }, param) {
    return new Promise((resolve, reject) => {
      address_info(param.id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 修改地址
  edit_address({ commit }, param) {
    return new Promise((resolve, reject) => {
      edit_address(param.id, param.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除地址
  delete_address({ commit }, param) {
    return new Promise((resolve, reject) => {
      delete_address(param.id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取默认地址
  get_default({ commit }) {
    return new Promise((resolve, reject) => {
      get_default()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 设置默认地址
  set_default({ commit }, param) {
    return new Promise((resolve, reject) => {
      set_default(param.id, param.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
