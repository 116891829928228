import request from "@/utils/request";
export function default_lottery() {
  let url = "/api/lottery/default_lottery";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function default_lottery_count() {
  let url = "/api/lottery/default_lottery_count";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function do_default_lottery() {
  let url = "/api/lottery/do_default_lottery";
  let data = {};
  return request({
    url: url,
    method: "post",
    data,
  });
}

export function new_lottery() {
  let url = "/api/lottery/new_lottery";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function new_lottery_count() {
  let url = "/api/lottery/new_lottery_count";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function do_new_lottery() {
  let url = "/api/lottery/do_new_lottery";
  let data = {};
  return request({
    url: url,
    method: "post",
    data,
  });
}

export function user_lottery_log() {
  let url = "/api/lottery/user_lottery_log";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function gundong_lottery_log() {
  let url = "/api/lottery/gundong_lottery_log";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function new_lottery_log() {
  let url = "/api/lottery/new_lottery_log";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}
