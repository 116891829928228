import request from "@/utils/request";
export function product_list() {
  let url = "/api/product/list";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function product_search(page,data) {
  let url = "/api/product/search";
  data["page"] = page;
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function product_info(data) {
  let url = "/api/product/info";
  return request({
    url: url,
    method: "get",
    data,
  });
}
