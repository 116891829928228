import request from "@/utils/request";

export function place_vip_order(data) {
  let url = "/api/place_vip_data"; //提交订单
  return request({
    url: url,
    method: "post",
    data,
  });
}

export function cancel_vip_order() {
  let url = "/api/cancel_vip_order"; //提交订单
  let data = {};
  return request({
    url: url,
    method: "post",
    data,
  });
}
