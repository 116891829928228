import request from "@/utils/request";
export function address_list() {
  //地址列表（全，进件，修改地址使用）
  let url = "/api/address";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}
export function add_address(data) {
  //获取商户经营地址
  let url = "/api/address";
  return request({
    url: url,
    method: "post",
    data,
  });
}

export function address_info(id) {
  //地址列表（省市，绑定银行卡用）
  let url = "/api/address/" + id;
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function edit_address(id, data) {
  //地址列表（省市，绑定银行卡用）
  let url = "/api/address/" + id;
  return request({
    url: url,
    method: "put",
    data,
  });
}

export function delete_address(id) {
  //地址列表（省市，绑定银行卡用）
  let url = "/api/address/" + id;
  return request({
    url: url,
    method: "delete",
  });
}

export function get_default() {
  //地址列表（省市，绑定银行卡用）
  let url = "/api/address/get_default";
  return request({
    url: url,
    method: "get",
    // data,
  });
}

export function set_default(id, data) {
  //地址列表（省市，绑定银行卡用）
  let url = "/api/address/set_default/" + id;
  return request({
    url: url,
    method: "put",
    data,
  });
}
