import {
    merchant_info_list
  } from '@/api/merchant_information'
  
  
  const state = {}
  const mutations = {}
  const actions = {
    // 品牌列表
    address_list_paginate({
      commit
    }, param) {
      return new Promise((resolve, reject) => {
        address_list_paginate(param.page, param.data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 商户信息
    merchant_info_list({
      commit
    }, param) {
      return new Promise((resolve, reject) => {
        merchant_info_list().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    bank_address_list({
      commit
    }, param) {
      return new Promise((resolve, reject) => {
        bank_address_list().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 添加品牌
    brand_add({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        brand_add(data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 品牌详情
    brand_info({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        brand_info(id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 更新品牌
    brand_update({
      commit
    }, param) {
      return new Promise((resolve, reject) => {
        brand_update(param.id, param.data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 删除品牌
    brand_delete({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        brand_delete(id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
  }
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }