import { tax_info, wallet_record_list, do_withdraw } from "@/api/withdraw";

const state = {};
const mutations = {};
const actions = {
  // 用户信息
  tax_info({ commit }) {
    return new Promise((resolve, reject) => {
      tax_info()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 用户信息
  wallet_record_list({ commit }, param) {
    return new Promise((resolve, reject) => {
      wallet_record_list(param.page, param.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  do_withdraw({ commit }, param) {
    return new Promise((resolve, reject) => {
      do_withdraw(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
