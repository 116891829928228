import {
  import_merchant_add,
  sms_add
} from '@/api/import_merchant'

import {
  getToken,
  setToken,
  removeToken,
} from '@/utils/auth'
const state = {
  token: getToken(),
}
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
}
const actions = {
  // 进件接口
  import_merchant_add({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      import_merchant_add(data).then(response => {
        // let sign = data.sign;
        // delete data.sign;
        // delete data.expires_at;
        commit('SET_TOKEN', response.data.token_type + ' ' + response.data.access_token)
        // commit('SET_ROLES', response.data.roles)
        // commit('SET_ACCOUNT', response.data.account)
        setToken(response.data.token_type + ' ' + response.data.access_token)
        // setRoles(response.data.roles);
        // setAccount(response.data.account_info);
        resolve(response)
        // if (signature(data) == sign) {
        
        // } else {
        //   reject({ "code": "-99", "message": "登录失败,请重试" })
        // }
      }).catch(error => {
        reject(error)
      })
    })
  },
  sms_add({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      sms_add(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}