import {
  product_list,
  product_search,
  product_info
} from "@/api/product";

const state = {};
const mutations = {};
const actions = {
  // 获取产品列表
  product_list({
    commit
  }) {
    return new Promise((resolve, reject) => {
      product_list()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 查询产品列表
  product_search({
    commit
  }, param) {
    return new Promise((resolve, reject) => {
      product_search(param.page, param.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 产品详情
  product_info({
    commit
  }, param) {
    return new Promise((resolve, reject) => {
      product_info(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};