import {
    trade_log_paginate
} from '@/api/trade'


const state = {}
const mutations = {}
const actions = {
    // 角色列表
    trade_log_paginate({
        commit
    }, param) {
        return new Promise((resolve, reject) => {
            trade_log_paginate(param.page, param.data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}