import request from "@/utils/request";
export function order_list(data) {
  //订单列表
  let url = "/api/order/list";
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function cancel_order(data) {
  //订单列表
  let url = "/api/order/cancel_order";
  return request({
    url: url,
    method: "get",
    data,
  });
}
export function place_order(data) {
  let url = "/api/order/place_order"; //提交订单
  return request({
    url: url,
    method: "post",
    data,
  });
}