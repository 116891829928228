// -----------------------------------------------------轮播图
import request from '@/utils/request'
export function banner_paginate(data) {
  let url = "/api/banner";
  return request({
    url: url,
    method: 'get',
    data
  })
}
export function notice_list_paginate(data) {
  let url = "/api/notice_list";
  return request({
    url: url,
    method: 'get',
    data
  })
}
export function notice_detail_info(data) {
  let url = "/api/notice_detail";
  return request({
    url: url,
    method: 'get',
    data
  });
}
