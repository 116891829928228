import {
  machine_info,
  check_machine_exists,
  get_machine_count,
  login_bind_machine,
  machine_list,
  change_default_machine,
  bind_new_machine,
} from "@/api/machine";
import {
  getMacineState,
  setMachineToken,
} from "@/utils/auth";
const state = {
  bind_machine_state:getMacineState()
};
const mutations = {
  SET_MACHINE_STATE:(state,bind_machine_state)=>{
    state.bind_machine_state=bind_machine_state
  }
};
const actions = {
  // 获取默认机器信息
  machine_info({ commit }, param) {
    return new Promise((resolve, reject) => {
      machine_info()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取已绑定的机器列表
  machine_list({ commit }, param) {
    return new Promise((resolve, reject) => {
      machine_list()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取是否绑定机器
  check_machine_exists({ commit }, param) {
    return new Promise((resolve, reject) => {
      check_machine_exists()
        .then((response) => {
          commit('SET_MACHINE_STATE', response.data.bind_machine_state + ' ' + response.data.bind_machine_state)
          setMachineToken(response.data.bind_machine_state)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //绑定机器
  login_bind_machine({ commit }, param) {
    return new Promise((resolve, reject) => {
      login_bind_machine(param)
        .then((response) => {
          commit('SET_MACHINE_STATE', response.data.bind_machine_state + ' ' + '1')
          setMachineToken("1")
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取机器数量
  get_machine_count({ commit }, param) {
    return new Promise((resolve, reject) => {
      get_machine_count()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 修改默认机器
  change_default_machine({ commit }, param) {
    return new Promise((resolve, reject) => {
      change_default_machine(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 绑定新机器
  bind_new_machine({ commit }, param) {
    return new Promise((resolve, reject) => {
      bind_new_machine(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
