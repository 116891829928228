import { index_data,order_logs } from "@/api/home";

const state = {};
const mutations = {};
const actions = {
  index_data({ commit }, param) {
    return new Promise((resolve, reject) => {
      index_data()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  order_logs({ commit }, param) {
    return new Promise((resolve, reject) => {
      order_logs()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
