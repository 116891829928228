import { category_list } from "@/api/category";

const state = {};
const mutations = {};
const actions = {
  // 获取分类列表
  category_list({ commit }, param) {
    return new Promise((resolve, reject) => {
      category_list()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
