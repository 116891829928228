
import request from '@/utils/request'
export function sn_number_paginate(data) {
    // console.log(data)
    let url = "/api/check_sn_number";
    return request({
      url: url,
      method: 'get',
      data
    })
  }