import request from "@/utils/request";
export function category_list() {
  let url = "/api/category/list";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}
