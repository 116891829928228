import axios from "axios";
import store from "@/store";
import router from "@/router";
import NProgress from "nprogress";
import { getToken } from "@/utils/auth";
import { Toast } from "vant";
import { create_post_data, create_search_param } from "./signdata";
import { removeToken } from "@/utils/auth";
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    let url = config.url;
    let data = config.data;
    let method = config.method;
    // console.log(config);
    //登录接口独立处理
    if (url != "/api/oauth_wechat") {
      switch (method) {
        case "get":
        case "delete":
          // console.log(data);
          let param = create_search_param(data, true);
          config.url += param;
          delete config.data;
          break;
        case "post":
        case "put":
          data = create_post_data(data);
          config.data = data;
          break;
        default:
          console.log("未知请求");
          break;
      }
    }
    if (store.getters.token) {
      config.headers["Accept"] = "Accept:application/json";
      config.headers["Authorization"] = getToken();
    }
    return config;
  },
  (error) => {
    // do something with request error // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    if (response.status == "200") {
      const res = response.data;
      if (res instanceof Blob) {
        return res;
      } else {
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== "1") {
          Toast.fail(res.message || "Error");
          // Message({
          //   message: this.$toast(res.message) || 'Error',
          //   type: 'error',
          //   duration: 2 * 1000
          // })
          // return Promise.reject(new Error(res.message || 'Error'))
          return res;
        } else {
          return res;
        }
      }
    } else {
      Toast.fail("系统异常");
      return { code: "0", message: "系统异常" };
    }
  },
  (error) => {
    if (error.response.status == "401") {
      // next("/oauth_wechat");
      removeToken();
      router.replace("/oauth_wechat");
      return Promise.reject(error.response);
    } else {
      // console.log('err' + error) // for debug
      Toast.fail(error.message);
      // this.$toast(error.message)
      // Message({
      //   message: error.message,
      //   type: 'error',
      //   duration: 2 * 1000
      // })
      return Promise.reject(error.response);
    }
  }
);

export default service;
