import request from "@/utils/request";
export function tax_info() {
  let url = "/api/withdraw/tax_info";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function wallet_record_list(page, data) {
  let url = "/api/withdraw/wallet_record_list";
  data["page"] = page;
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function do_withdraw(data) {
  let url = "/api/withdraw/do_withdraw";
  return request({
    url: url,
    method: "post",
    data,
  });
}
