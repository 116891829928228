import request from '@/utils/request'
export function check_bank_exists(data) { //是否绑定结算卡接口
  let url = "/api/check_bank_exists";
  return request({
    url: url,
    method: 'get',
    data
  })
}


export function get_bank_list() { //获取结算卡
  let url = "/api/get_bankinfo";
  let data = {}
  return request({
    url: url,
    method: 'get',
    data
  })
}
export function bankinfo_add(data) { //新增结算卡
  let url = "/api/add_bankinfo";
  return request({
    url: url,
    method: 'post',
    data
  });
}


export function change_bankinfo_update(data) { //修改结算卡
  let url = "/api/change_bankinfo";
  return request({
    url: url,
    method: 'post',
    data
  });
}


