import request from "@/utils/request";
export function bank_info() {
  //银行列表
  let url = "/api/bank/info";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function bank_bind(data) {
  //银行列表
  let url = "/api/bank/bank_bind";
  return request({
    url: url,
    method: "post",
    data,
  });
}
