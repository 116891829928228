import { place_vip_order,cancel_vip_order } from "@/api/viporder";

const state = {};
const mutations = {};
const actions = {
  place_vip_order({ commit }, param) {
    return new Promise((resolve, reject) => {
      place_vip_order(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cancel_vip_order({ commit }) {
    return new Promise((resolve, reject) => {
      cancel_vip_order()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
