import {
  user_info,
  wallet_info,
  idcard_info,
  idcard_bind,
  son_list,
  promotion_bonus,
  promotion_total,
  exchange_vip,
  service_url
} from "@/api/user";

const state = {};
const mutations = {};
const actions = {
  // 用户信息
  user_info({ commit }, param) {
    return new Promise((resolve, reject) => {
      user_info()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 用户信息
  wallet_info({ commit }, param) {
    return new Promise((resolve, reject) => {
      wallet_info()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 用户信息
  service_url({ commit }, param) {
    return new Promise((resolve, reject) => {
      service_url()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  idcard_bind({ commit }, data) {
    return new Promise((resolve, reject) => {
      idcard_bind(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 用户信息
  idcard_info({ commit }, param) {
    return new Promise((resolve, reject) => {
      idcard_info()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 下级列表
  son_list({ commit }, param) {
    return new Promise((resolve, reject) => {
      son_list(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 下级列表
  promotion_bonus({ commit }, param) {
    return new Promise((resolve, reject) => {
      promotion_bonus(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 推荐奖金统计
  promotion_total({ commit }, param) {
    return new Promise((resolve, reject) => {
      promotion_total()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 推荐奖金统计
  exchange_vip({ commit }, param) {
    return new Promise((resolve, reject) => {
      exchange_vip(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
