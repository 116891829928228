import {
    check_bank_exists,
    bankinfo_add,
    change_bankinfo_update,
    get_bank_list
} from '@/api/bind_card'


const state = {}
const mutations = {}
const actions = {
    // 角色列表
    adminer_paginate({
        commit
    }, param) {
        return new Promise((resolve, reject) => {
            adminer_paginate(param.page, param.data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 是否绑定判断
    check_bank_exists({
        commit
    }, param) {
        return new Promise((resolve, reject) => {
            check_bank_exists().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    get_bank_list({
        commit
    }, param) {
        return new Promise((resolve, reject) => {
            get_bank_list().then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 添加结算卡
    bankinfo_add({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            bankinfo_add(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 角色详情
    // notice_detail_info({
    //     commit
    // }, id) {
    //     return new Promise((resolve, reject) => {
    //         notice_detail_info(id).then(response => {
    //             resolve(response)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
    // 修改结算卡
    change_bankinfo_update({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            change_bankinfo_update(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 删除角色
    // adminer_delete({
    //     commit
    // }, id) {
    //     return new Promise((resolve, reject) => {
    //         adminer_delete(id).then(response => {
    //             resolve(response)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}