import request from '@/utils/request'
export function trade_log_paginate(page, data) {
    console.log(page)
    let url = "/api/trade_log";
    data['page'] = page
    return request({
      url: url,
      method: 'get',
      data
    })
  }