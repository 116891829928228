import {
  banner_paginate,
  notice_list_paginate,
  notice_detail_info
} from '@/api/banner'


const state = {}
const mutations = {}
const actions = {
  // banner列表
  banner_paginate({
    commit
  }) {
    return new Promise((resolve, reject) => {
      banner_paginate().then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 消息列表
  notice_list_paginate({
    commit
  }) {
    return new Promise((resolve, reject) => {
      notice_list_paginate().then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  notice_detail_info({
    commit
}, data) {
    return new Promise((resolve, reject) => {
      notice_detail_info(data).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
},

}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}