import { bank_info, bank_bind } from "@/api/bank";

const state = {};
const mutations = {};
const actions = {
  // 银行卡详情
  bank_info({ commit }, param) {
    return new Promise((resolve, reject) => {
      bank_info()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 银行卡绑定
  bank_bind({ commit }, param) {
    return new Promise((resolve, reject) => {
      bank_bind(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
