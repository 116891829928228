import request from "@/utils/request";
export function machine_info() {
  let url = "/api/machine/info";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function machine_list() {
  let url = "/api/machine/list";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function check_machine_exists() {
  let url = "/api/check_machine";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function get_machine_count() {
  let url = "/api/machine/get_count";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function login_bind_machine(data) {
  let url = "/api/bind_machine";
  return request({
    url: url,
    method: "post",
    data,
  });
}

export function change_default_machine(data) {
  let url = "/api/machine/change_default_machine";
  return request({
    url: url,
    method: "post",
    data,
  });
}

export function bind_new_machine(data) {
  let url = "/api/machine/bind_new_machine";
  return request({
    url: url,
    method: "post",
    data,
  });
}