import Vue from "vue";
import Router from "vue-router";

const home = () => import("../views/home/Home.vue");
const cart = () => import("../views/cart/Cart.vue");
const cart_list = () => import("../views/cart/cart_list.vue");
const cart_detail = () => import("../views/cart/cart_detail.vue");
const Profile = () => import("../views/profile/Profile.vue");
const lottery = () => import("../views/lottery/lottery.vue");
const mine = () => import("../views/mine/mine.vue");
const oauth_wechat = () => import("../views/oauth_wechat/oauth_wechat.vue");
const equity_bind = () => import("../views/oauth_wechat/equity_bind.vue");
const order = () => import("../views/order/order.vue");
const certification = () => import("../views/certification/certification.vue");
const progresss = () => import("../views/progresss/progresss.vue");
const settlement_card_list = () =>
  import("../views/settlement_card/settlement_card_list.vue");
const settlement_card_edit = () =>
  import("../views/settlement_card/settlement_card_edit.vue");
const settlement_card_add = () =>
  import("../views/settlement_card/settlement_card_add.vue");
const address_list = () => import("../views/address/address_list.vue");
const address_edit = () => import("../views/address/address_edit.vue");
const address_add = () => import("../views/address/address_add.vue");
const withdraw = () => import("../views/withdraw/withdraw.vue");
const withdraw_list = () => import("../views/withdraw/withdraw_list.vue");
const round_lottery = () => import("../views/lottery/round_lottery.vue");
const referral_friend_reward = () =>
  import("../views/referral_friend_reward/referral_friend_reward.vue");
const equity_serve = () => import("../views/equity/equity_serve.vue");
const equity_exchange = () => import("../views/equity/equity_exchange.vue");
const equity_detail = () => import("../views/equity/equity_detail.vue");
const member = () => import("../views/member/member.vue");
const members = () => import("../views/member/members.vue");
const bind_device = () => import("../views/bind_device/bind_device.vue");
const submit_order = () => import("../views/order/submit_order.vue");

Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: home,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/cart",
    component: cart,
    meta: {
      title: "我的购物车",
    },
  },
  {
    path: "/profile",
    component: Profile,
    meta: {
      title: "个人信息",
    },
  },
  {
    path: "/lottery",
    component: lottery,
    meta: {
      title: "幸运大抽奖",
    },
  },
  {
    path: "/round_lottery",
    component: round_lottery,
    meta: {
      title: "幸运大抽奖1",
    },
  },
  {
    path: "/mine",
    name: "mine",
    component: mine,
    meta: {
      title: "我的",
    },
  },
  {
    path: "/progresss",
    component: progresss,
    meta: {
      title: "进度",
    },
  },
  {
    path: "/oauth_wechat",
    component: oauth_wechat,
    meta: {
      title: "vip权益服务",
    },
  },
  {
    path: "/equity_bind",
    component: equity_bind,
    meta: {
      title: "vip权益绑定",
    },
  },
  {
    path: "/cart_list",
    component: cart_list,
    meta: {
      title: "创意配件",
    },
  },
  {
    path: "/cart_detail",
    component: cart_detail,
    meta: {
      title: "创意配件",
    },
  },
  {
    path: "/order",
    component: order,
    meta: {
      title: "我的订单",
    },
  },
  {
    path: "/certification",
    component: certification,
    meta: {
      title: "实名认证",
    },
  },
  {
    path: "/settlement_card_list",
    component: settlement_card_list,
    meta: {
      title: "绑定结算卡",
    },
  },
  {
    path: "/settlement_card_add",
    component: settlement_card_add,
    meta: {
      title: "新增结算卡",
    },
  },
  {
    path: "/settlement_card_edit",
    component: settlement_card_edit,
    meta: {
      title: "修改结算卡",
    },
  },
  {
    path: "/address_list",
    component: address_list,
    meta: {
      title: "地址列表",
    },
  },
  {
    path: "/address_add",
    component: address_add,
    meta: {
      title: "新增地址",
    },
  },
  {
    path: "/address_edit",
    component: address_edit,
    meta: {
      title: "修改地址",
    },
  },
  {
    path: "/withdraw",
    component: withdraw,
    meta: {
      title: "提现",
    },
  },
  {
    path: "/withdraw_list",
    component: withdraw_list,
    meta: {
      title: "提现列表",
    },
  },
  {
    path: "/referral_friend_reward",
    component: referral_friend_reward,
    meta: {
      title: "推荐好友奖励",
    },
  },
  {
    path: "/equity_serve",
    component: equity_serve,
    meta: {
      title: "权益服务",
    },
  },
  {
    path: "/equity_exchange",
    component: equity_exchange,
    meta: {
      title: "权益码兑换",
    },
  },
  {
    path: "/member",
    component: member,
    meta: {
      title: "会员",
    },
  },
  {
    path: "/members",
    component: members,
    meta: {
      title: "权益会员",
    },
  },
  {
    path: "/bind_device",
    component: bind_device,
    meta: {
      title: "绑定新设备",
    },
  },
  {
    path: "/equity_detail",
    component: equity_detail,
    meta: {
      title: "权益分明细",
    },
  },
  {
    path: "/submit_order",
    component: submit_order,
    name: "submit_order",
    meta: {
      title: "提交订单",
    },
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 500);
      });
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
