import request from '@/utils/request'
export function index_data() {
  let url = "/api/home/index_data";
  let data = {}
  return request({
    url: url,
    method: 'get',
    data
  })
}
export function order_logs() {
  let url = "/api/home/order_logs";
  let data = {}
  return request({
    url: url,
    method: 'get',
    data
  })
}