import {
    sn_number_paginate
  } from '@/api/sn_number'
  
  
  const state = {}
  const mutations = {}
  const actions = {
    // 品牌列表
    sn_number_paginate({
      commit
    }, param) {
      return new Promise((resolve, reject) => {
        sn_number_paginate(param).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 品牌列表-无分页
   sn_number({
      commit
    }, param) {
      return new Promise((resolve, reject) => {
        sn_number().then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 添加品牌
    brand_add({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        brand_add(data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 品牌详情
    brand_info({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        brand_info(id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 更新品牌
    brand_update({
      commit
    }, param) {
      return new Promise((resolve, reject) => {
        brand_update(param.id, param.data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 删除品牌
    brand_delete({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        brand_delete(id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
  }
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }