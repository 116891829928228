import request from "@/utils/request";
export function user_info() {
  let url = "/api/user/info";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function wallet_info() {
  let url = "/api/user/wallet_info";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function service_url() {
  let url = "/api/user/service_url";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function idcard_info() {
  let url = "/api/idcard/info";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function idcard_bind(data) {
  let url = "/api/idcard/idcard_bind";
  return request({
    url: url,
    method: "post",
    data,
  });
}

export function son_list(data) {
  let url = "/api/son_list";
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function promotion_bonus(data) {
  let url = "/api/promotion_bonus";
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function promotion_total() {
  let url = "/api/promotion_total";
  let data = {};
  return request({
    url: url,
    method: "get",
    data,
  });
}

export function exchange_vip(data) {
  let url = "/api/exchange_vip";
  return request({
    url: url,
    method: "post",
    data,
  });
}
