import { order_list, cancel_order,place_order } from "@/api/order";

const state = {};
const mutations = {};
const actions = {
  order_list({ commit }, param) {
    return new Promise((resolve, reject) => {
      order_list()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cancel_order({ commit }, param) {
    return new Promise((resolve, reject) => {
      cancel_order(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  place_order({ commit }, param) {
    return new Promise((resolve, reject) => {
      place_order(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
