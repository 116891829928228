import request from "@/utils/request";

export function oauth_wechat_add(code) {
  let data = [];
  return request({
    url: "/api/oauth_wechat?code=" + code,
    method: "post",
    data,
  });
}

// export function yzj_login(data) {
//   return request({
//     url: '/api/yzj_login',
//     method: 'post',
//     data
//   })
// }

// export function logout() {
//   return request({
//     url: '/api/logout',
//     method: 'get'
//   })
// }
